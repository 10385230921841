<template>
  <my-map-html :position="mapinfo.longitudeLatitude" style="z-index: 999999;position: relative" :offset="[-200,-250]" >
  <my-dv-box z-index="1" class="ziyuanmaptanchuang" left="0" v-if="res"
             top="0" width="481px" height="210px">
    <my-dv-box class="title" x-align="center" height="53px" width="444px">
      <div class="box" >
        <div class="left">{{res.pondName}}</div>
        <div class="right flex-center" @click="gotott">查看监控</div>
      </div>
    </my-dv-box>
    <my-dv-box width="444px" height="60px" top="72px" x-align="center">
      <div class="flex-between"  v-if="res">
        <el-image style="width: 117px;height: 60px" :src="res.image"></el-image>
        <div v-if="res" class="detailbox flex-between">
          <div class="flex-between-column">
            <div>负责人：<span>{{res.personInCharge}}</span></div>
            <div>养殖面积：<span>{{res.area}}亩</span></div>
          </div>
          <div class="flex-between-column">
            <div>联系电话：<span>{{res.phone}}</span></div>
            <div>预估产量：<span>{{res.estimatedProduction}}吨</span></div>
          </div>
        </div>
      </div>
    </my-dv-box>
    <my-dv-box width="444px" height="35px" bottom="30px" x-align="center">
      <div class="flexbb">
        <div>
          <div>PH值</div>
          <div>{{res.ph}}</div>
        </div>
        <div>
          <div>温度</div>
          <div>{{res.waterTemperature}}℃</div>
        </div>
        <div>
          <div>ORP</div>
          <div>{{res.orp}}</div>
        </div>
        <div>
          <div>氨氮值</div>
          <div>{{res.nitrogen}}mg/l</div>
        </div>
        <div>
          <div>溶氧量</div>
          <div>{{res.oxygen}}mg/l</div>
        </div>
        <div v-if="res.waterLevel">
          <div>水位</div>
          <div>{{res.waterLevel}}cm</div>
        </div>
      </div>
    </my-dv-box>
  </my-dv-box>
  </my-map-html>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "derewqrmaptanchuang",
  props:['left','top','mapinfo'],
  data(){
    return {
      res:null
    }
  },
  methods:{
    gotott(){
      this.$router.push('/camera?cid='+this.res.userInfo+'&cname='+this.res.pondName+'&back=/industrial/'+this.cname)
    },
  },
  mounted() {
    this.res = this.mapinfo.datainfo
    // industrycrabPondInfo(this.mapinfo.crabPondId).then(res=>{
    //   this.res=res.data
    // })
  },
  computed:{
    cname(){
      return this.$route.params.cname;
    },
    sleft(){
      return (this.left )+'px'
    },
    stop(){
      return (this.top )+'px'
    },
  },
};
</script>

<style scoped lang="scss">
.ziyuanmaptanchuang{
  background: url("~@/assets/images/yiyuanguanlimaptanchuan.png") no-repeat;
  background-size: 100% 100%;
  transform: translate(-10% , -5%);
  .title{
    border-bottom: 2px solid #1598C4;
    .box{
      width: 100%;height: 100%;
      display: flex;justify-content: space-between;align-items: center;
      .left{
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        margin-top: 8px;
      }
      .right{
        margin-top: 10px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        color: #FFFFFF;
        width: 86px;
        height: 28px;
        background: linear-gradient(100deg, #0F98E6 0%, #035E9C 100%);
        border-radius: 10px;
        line-height: 1em;
        margin-bottom: 5px;
        cursor: pointer;
      }
    }
  }
  .detailbox{
    height: 45px;
    width: calc(100% - 128px);
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    white-space:nowrap;
    text-align: center;
    .flex-between-column{
      >div{
        display: flex;
        align-items: center;
      }
    }
    .errimage{
      margin-left: 5px;
    }
    .warning{
      color: #FF3445;
    }
  }
}
.flexbb{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 24px;
}
</style>
