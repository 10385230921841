<template>
  <my-dv-box  class="industrialmap" width="100%"
              height="1000px" top="-30px"  x-align="center" >
    <my-map :zoom="zoom"
            height="100%"
            :minZoom="10"
            :maxZoom="18.5"
            @click="handleclickasda"
            :center="center" :adapter="adapter">
<!--      -->
<!--      <my-map-geo :json="xinbei" :stroke="{
          width: 2,
          color: '#00ffff'
        }" :fill="'rgba(69, 127, 208, .7)'"  :text="{
          fill: 'rgba(0, 0, 0, 0)',
          font: '14px'
        }"></my-map-geo>-->
      <template v-for="(item,key) in longitudeLatitudeListfiter">
        <my-map-image
            :key="key"
            :opacity="1"
            :offset="[0,1000]"
            :displacement="[0,0]"
            :scale="0.02"
            :props="item"
            :coordinate="item.longitudeLatitude"
            @click.stop="handleClick2"
            :src="getPointType(item.subjectType)" style="width: 50px;height: 50px"></my-map-image>
      </template>
        <ziyuanmaptanchuang v-if="mapinfo" :mapinfo="mapinfo"  :left="0" :top="0"></ziyuanmaptanchuang>
    </my-map>
    <my-dv-box class="l" height="100%" width="433px" top="0" left="0"></my-dv-box>
    <my-dv-box class="r" height="100%" width="433px" top="0" right="0"></my-dv-box>
    <my-dv-box class="t" height="150px" width="100%" top="0" left="0"></my-dv-box>
    <my-dv-box class="b" height="200px" width="100%" bottom="0" left="0"></my-dv-box>

    <div class="showmarks">
      <el-checkbox-group v-model="checkList">
        <el-checkbox :label="1">
          <img class="icon" src="@/assets/images/icon/示范基地.svg" alt="">
          <span>示范基地</span>
        </el-checkbox>
        <el-checkbox :label="2">
          <img class="icon" src="@/assets/images/icon/合作社.svg" alt="">
          <span>合作社</span>
        </el-checkbox>
        <el-checkbox :label="3">
          <img class="icon" src="@/assets/images/icon/品牌企业.svg" alt="">
          <span>企业</span>
        </el-checkbox>
        <el-checkbox :label="4">
          <img class="icon" src="@/assets/images/icon/家庭农场2.svg" alt="">
          <span>家庭农场</span>
        </el-checkbox>
      </el-checkbox-group>
    </div>
  </my-dv-box>
</template>

<script>
import img1 from '@/assets/images/icon/地图示范基地.png'
import img2 from '@/assets/images/icon/地图合作社.png'
import img3 from '@/assets/images/icon/地图品牌企业.png'
import img4 from '@/assets/images/icon/地图养殖大户.png'
// import {homePageCrabPond} from "@/utils/api";
import xinbei from '@json/xinbei.json'
import {screen} from "@/utils/api"
import Ziyuanmaptanchuang from "./derewqrmaptanchuang.vue";
export default {
  name: "industrialmap2",
  components: {Ziyuanmaptanchuang},
  data(){
    let geojson = Object.assign({},xinbei)
    geojson.features=geojson.features.filter(i=>i.properties.name==='孟河镇')
    return {
      xinbei:geojson,
      mapinfo:{},
      offsetY:1,
      offsetX:1,
      checkList: [1,2,3,4],
      longitudeLatitudeList:[],
      longitudeLatitudeListfiter:[]
    }
  },
  watch:{
    checkList(){
      this.longitudeLatitudeListfiter=this.longitudeLatitudeList.filter(i=>this.checkList.indexOf(i.subjectType)>-1)
    }
  },
  computed:{
    cname(){
      return this.$route.params.cname;
    },
    zoom(){
      if (this.cname==='孟河镇'){
        return 14.5
      }
      if (this.cname==='魏村街道'){
        return 15
      }
      return 13.8;
    },
    center(){
      if (this.cname==='孟河镇'){
        return [119.89427856944007, 32.005566584495855]
      }
      if (this.cname==='魏村街道'){
        return [119.88527856944007, 31.970566584495855]
      }
      return [119.89327856944007, 31.990566584495855]
    },
  },
  created(){
    screen().then((result) => {
      result.data?.map(i=>{
        if (this.cname===i.areaName){
          this.longitudeLatitudeList.push({
            name:i.pondName,
            crabPondId:i.farmId,
            subjectType:i.type,
            longitudeLatitude:i.longitude.split(',').map(ii=>{return parseFloat(ii.trim())}),
            datainfo:i
          })
        }

        this.longitudeLatitudeListfiter=this.longitudeLatitudeList;
      })
    })
  },
  methods:{
    handleclickasda(e){
      this.offsetX=0
      this.offsetY=0
      this.mapinfo=null
    },
    handleClick2(e,f){
      setTimeout(()=>{
        this.handleClick(e,f)
      },200)
    },
    handleClick(e,f){
      this.offsetX=e?e?.pixel_[0]:0
      this.offsetY=e?e?.pixel_[1]:0
      this.mapinfo=f?f?.values_:null
    },
    getPointType(type) {
      var img = ''
      if (type == 1) {
        img = img1
      }
      if (type == 2) {
        img = img2
      }
      if (type == 3) {
        img = img3
      }
      if (type == 4) {
        img = img4
      }
      return img
    },
    adapter({TileLayer, XYZ}){
      return new TileLayer({
        source: new XYZ({
          url:'http://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/WMTS/tile/1.0.0/World_Imagery/default/GoogleMapsCompatible/{z}/{y}/{x}.jpg',
          // url:'http://gac-geo.googlecnapps.cn/maps/vt?lyrs=s&x={x}&y={y}&z={z}',
          // url:'http://webst04.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}&lang=zh_cn'
        })
      })
    }
  },
};
</script>

<style scoped lang="scss">
.l{
  background: linear-gradient(90deg, #00122A 20%, RGBA(5, 17, 33, 0) 100%);
}
.r{
  background: linear-gradient(270deg, #00122A 20%, RGBA(5, 17, 33, 0) 100%);
}
.t{
  background: linear-gradient(180deg,
      #00122A 0%,
      RGBA(0, 18, 42, 0.9) 30%,
      RGBA(0, 18, 42, 0.7) 60%,
      RGBA(5, 17, 33, 0.3) 90%,
      RGBA(5, 17, 33, 0.01) 100%);
}
.b{
  background: linear-gradient(0deg, #00122A 20%,RGBA(0, 18, 42, 0.7) 50%, RGBA(5, 17, 33, 0) 100%);
}
.showmarks {
  right: 500px !important;
  bottom: 240px !important;
  background: rgba(0, 40, 104, 0.5);
  z-index: 9999999999;
}
</style>
