<template>
<!--<div ref="pie"></div>-->
  <my-dv-box width="200px" height="200px">
    <my-dv-pie fit :columns="columns" :rows="rows" :settings="settings" :legend="false" color="" :extend="extend"></my-dv-pie>
<!--    <my-dv-ring fit :columns="columns" :settings="settings" :extend="extend" :radius="80"   :use-gap="true" :rows="rows" :track-width="10"></my-dv-ring>-->
    <my-dv-box content-align="center" x-align="center" y-align="middle" width="100%">
      <div class="totalnum">{{num4}}条</div>
      <div class="totaltext">总上传数据</div>
    </my-dv-box>
  </my-dv-box>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: "staticpie",
  props:{
    num1:{
      type:Number,
      default:100,
    },
    num2:{
      type:Number,
      default:100,
    },
    num3:{
      type:Number,
      default:100,
    },
    num4:{
      type:Number,
      default:100,
    }
  },
  data(){
    return {
      extend:{
        color: ['#228FFE','#FBCE00','#00F8FF'],
        series:{
          label: {
            show: false,
          }
        }
      },
      settings:{
        radius: ['75%', '90%'],
      },
      columns: ['', ''],
    }
  },
  computed:{
    rows(){return [
      ['今日上传数据', this.num1],
      ['今日超标数据', this.num2],
      ['总超标数据',this.num3]
    ]}
  },
  mounted() {
    this.$nextTick(_=>{
      return;;
     let  {height,width}=this.$refs.pie.getBoundingClientRect();
      var myChart1 = echarts.init(this.$refs.pie);
      let option1 = {
        tooltip: {
          trigger: 'item',
          formatter: "{b}: {c} ({d}%)"
          /*formatter:function(val){   //让series 中的文字进行换行
               console.log(val);//查看val属性，可根据里边属性自定义内容
               var content = var['name'];
               return content;//返回可以含有html中标签
           },*/ //自定义鼠标悬浮交互信息提示，鼠标放在饼状图上时触发事件
        },//提示框，鼠标悬浮交互时的信息提示
      legend: {
        show: false,
      },//图例属性，以饼状图为例，用来说明饼状图每个扇区，data与下边series中data相匹配
        graphic:[
          {
            type:'text',
            top: height/2,
            left:'center',
            style:{
              text:this.num4+"条", //使用“+”可以使每行文字居中
              textAlign:'center',
              fontSize:22,
              fill:'#fff',
              width:30,
              height:30
            }
          },
          {
            type:'text',
            top: height/2+25,
            left:'center',
            style:{
              text:'总上传数据', //使用“+”可以使每行文字居中
              textAlign:'center',
              fontSize:14,
              fill:'#fff',
              width:30,
              height:30
            }
          }
        ],
      series: [
        {
          name:'用户统计',//tooltip提示框中显示内容
          type: 'pie',//图形类型，如饼状图，柱状图等
          radius: ['80%', '90%'],//饼图的半径，数组的第一项是内半径，第二项是外半径。支持百分比，本例设置成环形图。具体可以看文档或改变其值试一试
          //roseType:'area',是否显示成南丁格尔图，默认false
          lineStyle:{
            show:false,
          },
          label:{
            show:false,
          },
          labelLine:{
            show:false,
          },
          data: [
            {value: this.num1, name: '今日上传数据'},
            {value: this.num2, name: '今日超标数据'},
            {value:  this.num3, name: '总超标数据'},
          ],//数据，数据中其他属性，查阅文档
          color: ['#228FFE','#FBCE00','#00F8FF'],//各个区域颜色
        },//数组中一个{}元素，一个图，以此可以做出环形图
      ],//系列列表
    };
      myChart1.setOption(option1)
      myChart1.on('click',this.pieclick)
    });
  },
  methods:{
    pieclick(){
      console.log(arguments);
    },
  }
};
</script>

<style scoped lang="scss">
.totalnum{
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
}
.totaltext{
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
}
</style>
