<template>
  <my-dv-box  width="100%"   height="90%" top="100px">
    <div id="content" class="industrial" @click.stop="closeLongitudeLatitudedialogis">
      <div class="contant">
        <div class="contant-left-l" style="z-index: 11;">
          <div class="height11">
            <div class="h-title mt25 ml28">
              产业主体
            </div>
            <div class="h-content">

              <div class="industrial-main">
                <div class="industrial-main-item" @click="showdialog(index + 1, item)"
                     v-for="(item, index) in industrialMain" :key="index">
                  <img v-if="item === '重点村'" class="industrial-main-item-icon" src="@/assets/images/icon/重点村.svg">
                  <img v-if="item === '合作社'" class="industrial-main-item-icon" src="@/assets/images/icon/合作社.svg">
                  <img v-if="item === '家庭农场'" class="industrial-main-item-icon" src="@/assets/images/icon/养殖大户.svg">
                  <img v-if="item === '重点合作社'" class="industrial-main-item-icon" src="@/assets/images/icon/重点合作社.svg">
                  <img v-if="item === '品牌企业'" class="industrial-main-item-icon" src="@/assets/images/icon/品牌企业.svg">
                  <img v-if="item === '农资企业'" class="industrial-main-item-icon" src="@/assets/images/icon/农资企业.svg">
                  <img v-if="item === '示范基地'" class="industrial-main-item-icon" src="@/assets/images/icon/示范基地.svg">
                  <img v-if="item === '农技专家'" class="industrial-main-item-icon" src="@/assets/images/icon/农技专家.svg">
                  <img v-if="item === '产业专家'" class="industrial-main-item-icon" src="@/assets/images/icon/产业专家.svg">
                  <div class="industrial-main-item-body">
                    <p class="industrial-main-item-body-title">{{ item }}</p>
                    <p class="industrial-main-item-body-number">
                      <span>{{ zhuti['data' + (index + 1)].num }}</span>
                      <span>个</span>
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="height22">
            <div class="h-title ml28">
              产业面积趋势（亩）
            </div>
            <div class="h-content">
              <div ref="echarts-1"
                   style="width: 100%;height:100%;"></div>
            </div>
          </div>
          <div class="height22" style="margin-top: 10px">
            <div class="h-title ml28">
              产业面积排行（亩）
            </div>
            <div class="h-content">
              <!--            <div ref="echarts-2"-->
              <!--                 style="width: 100%;height:100%;"></div>-->
              <div ref="highcharts-2"
                   style="width: 100%;height:100%;"></div>
            </div>
          </div>
        </div>
        <div class="contant-center-c">
          <div class="contant-center-top" style="z-index: 11">
            <div class="c_c_t_div">
              <div>总面积</div>
              <div><span>{{ total.area }}</span>亩</div>
            </div>
            <div class="c_c_t_div">
              <div>总产量</div>
              <div><span>{{ total.output }}</span>吨</div>
            </div>
            <div class="c_c_t_div">
              <div>总产值</div>
              <div><span>{{ total.value }}</span>万元</div>
            </div>
          </div>
          <div class="contant-center-map">
          </div>
          <div class="contant-center-button" style="z-index: 11">
            <div class="h-title">
              产业资源分布
            </div>
            <div class="h-center-content">
              <!--          <div ref="echarts-3"-->
              <!--               style="width: 100%;height:100%;"></div>-->
              <!--            <highcharts :options="chartOptions" style="width: 100%;height:100%;margin-top:-2.5rem"></highcharts>-->

              <div class="highcharts-3" ref="highcharts-3"
                   style="width: 100%;height:100%;margin-top:-2.5rem"></div>
              <div class="highcharts_3_legend">
                <!-- #13e1e3  #ffa349  #66ceff-->
                <div></div>合作社
                <div></div>企业
                <div></div>种植大户
              </div>
            </div>
            <div class="qrcode">
              <span class="txt34">微信扫码体验</span>
              <img class="img5" src="@/assets/images/小程序.png">
            </div>
          </div>

        </div>
        <div class="contant-right-r" style="z-index: 11;">
          <div class="height33">
            <div class="h-title">
              数据统计
            </div>
            <div class="h-content">
              <div class="static" v-if="currentdata!=null">
                <div class="static-l">
                  <img class="images" src="@/assets/images/icon/static.png"/>
                  <staticpie
                      :num4="currentdata.totalUploads"
                      :num1="currentdata.uploadDataToday"
                      :num2="currentdata.todaySOutOfStandardData"
                      :num3="currentdata.totalOutOfSpecificationData"
                      style="width: 100%;height: 100%"></staticpie>

                </div>
                <div class="static-r">
                  <staticText @click.native="showPopup(1)" title="今日上传数据" :number="currentdata.uploadDataToday" color="#228FFE"></staticText>
                  <staticText @click.native="showPopup(2)" title="今日超标数据" :number="currentdata.todaySOutOfStandardData" color="#FBCE00"></staticText>
                  <staticText @click.native="showPopup(4)" title="总超标数据" :number="currentdata.totalOutOfSpecificationData" color="#00F8FF"></staticText>
                </div>
              </div>
            </div>
          </div>
          <div class="height33">
            <div class="h-title">
              产业产量趋势（吨）
            </div>
            <div class="h-content">
              <div ref="echarts-4"
                   style="width: 100%;height:100%;"></div>
            </div>
          </div>
          <div class="height33">
            <div class="h-title">
              产业产值趋势（亿元）
            </div>
            <div class="h-content">
              <div ref="echarts-5"
                   style="width: 100%;height:100%;"></div>
            </div>
          </div>
          <div class="height33" style="display: none">
            <div class="h-title">
              产业销量占比
              <div class="right"
                   style="display:none">
                <select v-model="selectyear"
                        class="selectyear">
                  <option v-for="(year ,i) in yearlist"
                          :key="i"
                          :value="year">{{ year }}
                  </option>
                </select>
              </div>
            </div>
            <div ref="echarts-6"
                 style="width: 100%;height:90%;"></div>
          </div>
        </div>
        <img class="contant-xz">
      </div>

      <Alert ref="Alert"
             class="dialog">
      </Alert>
      <div class="clear"></div>
      <div class="popup" v-if="popup_show">
        <div class="popup-close" @click="closePopup">
          <img src="@/assets/images/icon/f_popup_close.png">
        </div>
        <div class="popup-title">
          <span v-if="type == 1">今日上传数据</span>
          <span v-if="type == 2">今日超标数据</span>
          <span v-if="type == 3">总上传数据</span>
          <span v-if="type == 4">总超标数据</span>
        </div>
        <div class="popup-contriner">
          <div class="popup-standard">
            <div class="popup-standard-title">数据标准范围：</div>
            <div class="popup-standard-content">
              <span>pH值（{{ waterData.st.phScope }}）</span>
              <span>氨氮值（{{ waterData.st.ammoniaNitrogenScope }}）</span>
              <span>溶氧量（{{ waterData.st.oxygenContentScope }}）</span>
              <span>温度（{{ waterData.st.waterTScope }}）</span>
            </div>
          </div>
          <div class="popup-table">
            <div class="popup-table-th">
              <div class="popup-table-th-td w220">养殖户名称</div>
              <div class="popup-table-th-td w115">pH值</div>
              <div class="popup-table-th-td w115">氨氮值</div>
              <div class="popup-table-th-td w115">ORP</div>
              <div class="popup-table-th-td w115">溶氧量</div>
              <div class="popup-table-th-td w115">温度</div>
              <div class="popup-table-th-td w175">上传时间</div>
            </div>
            <div class="popup-table-body">

              <swiper class="swiper" :options="swiper_option" v-if="waterData.info.length > 0">
                <swiper-slide v-for="(info, index) in waterData.info" :key="index">
                  <div class="popup-table-tr">
                    <div class="popup-table-tr-td w220">
                      <span v-if="info.crabPondName">{{ info.crabPondName }}</span>
                    </div>
                    <div class="popup-table-tr-td w115" :class="{n: info.phVO.sign > 0}">
                      <span v-if="info.phVO.ph">{{ info.phVO.ph }}</span>
                    </div>
                    <div class="popup-table-tr-td w115" :class="{n: info.ammoniaNitrogenVO.sign > 0}">
                      <span v-if="info.ammoniaNitrogenVO.ammoniaNitrogen">{{ info.ammoniaNitrogenVO.ammoniaNitrogen }}mg/l</span>
                    </div>
                    <div class="popup-table-tr-td w115" :class="{n: info.orpVO.sign > 0}">
                      <span v-if="info.orpVO.orp">{{ info.orpVO.orp }}</span>
                    </div>
                    <div class="popup-table-tr-td w115" :class="{n: info.oxygenContentVO.sign > 0}">
                      <span v-if="info.oxygenContentVO.oxygenContent">{{ info.oxygenContentVO.oxygenContent }}mg/l</span>
                    </div>
                    <div class="popup-table-tr-td w115" :class="{n: info.waterTVO.sign > 0}">
                      <span v-if="info.waterTVO.waterT">{{ info.waterTVO.waterT }}℃</span>
                    </div>
                    <div class="popup-table-tr-td w175">
                      <span v-if="info.createTime">{{ info.createTime }}</span>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>



              <!--            <vue-seamless-scroll :data="waterData.info" :class-option="classOption" v-if="waterData.info.length > 0">-->
              <!--              <div class="popup-table-tr" v-for="(info, index) in waterData.info" :key="index">-->
              <!--                <div class="popup-table-tr-td w220">-->
              <!--                  <span v-if="info.crabPondName">{{ info.crabPondName }}</span>-->
              <!--                </div>-->
              <!--                <div class="popup-table-tr-td w115" :class="{n: info.phVO.sign > 0}">-->
              <!--                  <span v-if="info.phVO.ph">{{ info.phVO.ph }}</span>-->
              <!--                </div>-->
              <!--                <div class="popup-table-tr-td w115" :class="{n: info.ammoniaNitrogenVO.sign > 0}">-->
              <!--                  <span v-if="info.ammoniaNitrogenVO.ammoniaNitrogen">{{ info.ammoniaNitrogenVO.ammoniaNitrogen }}mg/l</span>-->
              <!--                </div>-->
              <!--                <div class="popup-table-tr-td w115" :class="{n: info.orpVO.sign > 0}">-->
              <!--                  <span v-if="info.orpVO.orp">{{ info.orpVO.orp }}</span>-->
              <!--                </div>-->
              <!--                <div class="popup-table-tr-td w115" :class="{n: info.oxygenContentVO.sign > 0}">-->
              <!--                  <span v-if="info.oxygenContentVO.oxygenContent">{{ info.oxygenContentVO.oxygenContent }}mg/l</span>-->
              <!--                </div>-->
              <!--                <div class="popup-table-tr-td w115" :class="{n: info.waterTVO.sign > 0}">-->
              <!--                  <span v-if="info.waterTVO.waterT">{{ info.waterTVO.waterT }}℃</span>-->
              <!--                </div>-->
              <!--                <div class="popup-table-tr-td w175">-->
              <!--                  <span v-if="info.createTime">{{ info.createTime }}</span>-->
              <!--                </div>-->
              <!--              </div>-->
              <!--            </vue-seamless-scroll>-->
            </div>
          </div>
        </div>
      </div>
      <ws-mask :show="mask_show" zIndex="999"></ws-mask>
    </div>
    <industrialmap></industrialmap>
  </my-dv-box>
</template>

<script>
import Alert from './alert.vue';
import staticText from "./static-text";
import staticpie from "./staticpie";

import wsMask from "./ws-mask"
// 引入highcharts
import * as echarts from 'echarts'
import Highcharts from 'highcharts'
import Highcharts3D from 'highcharts/highcharts-3d'
// import {industryHomePage, industryinfo, waterInfo} from "@/utils/api";
import Industrialmap from "./industrialmap2";
import {industryMainBody, regionchart2, regioncountMessage, regionvillage} from "@/utils/api";
import mqtt from "mqtt";
import {mapState} from "vuex";
Highcharts3D(Highcharts)


export default {
  name: "Home",
  data() {
    return {
      timer:null,
      currentdata:null,
      websock:null,
      industrialMain: ['重点村', '合作社', '家庭农场', '重点合作社', '品牌企业', '农资企业', '示范基地', '农技专家', '产业专家'],
      selectyear: "2022",
      yearlist: ["2021", "2022"],
      //产业面积趋势
      industrial: {
        x: [],
        y: [],
        min: 0,
        max: 0,
      },
      // 产业面积趋势（亩）
      industrialsort: {
        x: [],
        y: [],
      },
      //产业分布
      resources: [],
      //产业产量趋势（吨）
      yieldtrenddata: {
        x: [],
        y: [],
        min: 0,
        max: 0,
      },
      //产业产值趋势（亿元）
      industrialValuedata: {
        x: [],
        y: [],
        min: 0,
        max: 0,
      },
      industrialSalesdata: [
        {
          value: 0, name: "青虾",
          itemStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: '#fcc204 ' // 0% 处的颜色
              }, {
                offset: 1, color: '#ef6e18' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            }
          }
        },
        {
          value: 0, name: "螃蟹", itemStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: '#01b4ff ' // 0% 处的颜色
              }, {
                offset: 1, color: '#0336ff' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            }
          }
        },
      ],
      zhuti: {
        data1: {num: 0, type: 0}, //重点村
        data2: {num: 0, type: 0}, //合作社
        data3: {num: 0, type: 0}, //养殖大户
        data4: {num: 0, type: 0}, //重点和左忽
        data5: {num: 0, type: 0}, //品牌企业
        data6: {num: 0, type: 0}, //农资企业
        data7: {num: 0, type: 0}, //示范基地
        data8: {num: 0, type: 0}, //农技专家
        data9: {num: 0, type: 0}, //产业专家
      },
      total: {
        area: 0,
        output: 0,
        value: 0,
      },
      pieCurIndex: 0,
      popup_show: false,
      mask_show: false,
      more: true,
      type: 0,
      pageNum: 1,
      pageSize: 50,
      client:null,
      waterData: {
        st: {},
        info: []
      },
      swiper_option: {
        slidesPerView: 6,
        direction: 'vertical',
        freeMode: true,
        speed: 3000,
        loop: false,
        autoplay: {
          delay: 0,
          disableOnInteraction: false //就算触摸了也继续滚动
        }
      }
    };
  },
  beforeDestroy() {
    if (this.timer){
      clearInterval(this.timer)
      this.timer=null
    }
    if (this.client){
      this.client.end()
      this.client = null;
    }
  },
  mounted() {
    this.regioncountMessage()
    this.timer=setInterval(this.regioncountMessage,1000*30)
    regionchart2().then(res=>{
      this.industrialValuedata.x=res.data.industryOutputValue.x;
      this.industrialValuedata.y=res.data.industryOutputValue.y;
      this.industrialValue();

      this.yieldtrenddata.x=res.data.industryOutputTrend.x;
      this.yieldtrenddata.y=res.data.industryOutputTrend.y;
      this.yieldtrend()

      this.industrial.x=res.data.industryArea.x;
      this.industrial.y=res.data.industryArea.y;
      this.industrialArea();

      this.industrialsort.x=res.data.industryAreaTrend.x;
      this.industrialsort.y=res.data.industryAreaTrend.y;
      this.industrialaAreaSort();
      res.data.industryResourceDistribution?.x?.map((i,k)=>{
        this.resources.push([
            i,
          res.data.industryResourceDistribution.y[0][k],
          res.data.industryResourceDistribution.y[1][k],
          res.data.industryResourceDistribution.y[2][k],
        ]);
      })
      this.initHighCharts3(this.resources);
    })
    industryMainBody(this.getcid).then((result) => {
      this.zhuti.data1.num = result.data.village;
      this.zhuti.data2.num = result.data.demonstrationBase;
      this.zhuti.data3.num = result.data.familyFarm;
      this.zhuti.data4.num = result.data.cooperative;
      this.zhuti.data5.num = result.data.firm;
      this.zhuti.data6.num = result.data.agriculturalEnterprise;
      this.zhuti.data7.num = result.data.base;
      this.zhuti.data8.num = result.data.agronomist;
      this.zhuti.data9.num = result.data.industryExpert;


      this.total.output = result.data.totalOutput;//产量
      this.total.value = result.data.grossValueOfProduction;//产值
      this.total.area = result.data.grossArea;
      return;
      // //虾蟹占比
      // this.industrialSalesdata[0].value = result.data.greenShrimpRate;
      // this.industrialSalesdata[1].value = result.data.crabRate;
      // this.industrialSales();

      // 产业面积趋势
      // let a = {
      //   x: [1, 2, 3, 4, 5, 6, 7],
      //   y: [33, 15, 33, 56, 45, 66, 100],
      //   min: 0,
      //   max: 100,
      // };
      this.industrial.min = result.data.industryAreaTendVO.minArea;
      this.industrial.max = result.data.industryAreaTendVO.maxArea;
      result.data.industryAreaTendVO.industryAreaVOS.map((item) => {
        this.industrial.x.push(item.year);
        this.industrial.y.push(item.area);
      });
      this.industrialArea();

      //产业面积排行
      result.data.industryAreaRankVO.villageAreaVOS.map((item) => {
        this.industrialsort.x.push(item.villageName);
        this.industrialsort.y.push(item.area);

      });
      this.industrialaAreaSort();
      //产业资源分布     resources: [["村", "合作社", "企业", "种植大户"]],
      result.data.industryResourceDistVO.villageResourceVOS.map((item) => {
        this.resources.push([
          item.villageName,
          item.cooperative,
          item.brand,
          item.largeBreed,
        ]);
      });

      // this.industrialResources();
      this.initHighCharts3(result.data.industryResourceDistVO.villageResourceVOS)

      //产业产量趋势（吨）
      // // this.yieldtrenddata.min = result.data.industryOutputTendVO.minOutput;
      // // this.yieldtrenddata.max = result.data.industryOutputTendVO.maxOutput;
      // this.yieldtrenddata.min = false;
      // this.yieldtrenddata.max = false;
      // result.data.industryOutputTendVO.industryOutputVOS.map((item) => {
      //   this.yieldtrenddata.min == 0
      //   this.yieldtrenddata.x.push(item.year);
      //   this.yieldtrenddata.y.push(item.industryOutput);
      // });
      // this.yieldtrenddata.min = result.data.industryOutputTendVO.minOutput;
      // this.yieldtrenddata.max = result.data.industryOutputTendVO.maxOutput;
      this.yieldtrend();

      //产业产值趋势（亿元）
      this.industrialValuedata.min =
          result.data.industryOutputValueTendVO.minOutputValue;
      this.industrialValuedata.max =
          result.data.industryOutputValueTendVO.maxOutputValue;
      result.data.industryOutputValueTendVO.industryOutputValueVOS.map(
          (item) => {
            this.industrialValuedata.x.push(item.year);
            this.industrialValuedata.y.push(item.industryOutputValue);
          }
      );
      this.industrialValue();
    });
    // this.mqttMSG();
    //产业资源分布
  },
  methods: {
    connection() { // methods方法（在create中调用）
      this.isconnect = false;
      const wsuri = 'wss://shyapi.yousumgroup.com/'+ 'websocket';
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.soketmessage;
      setInterval(_ => {
        if (this.websock.readyState!==1){
          //尝试连接
          console.log('尝试连接')
          this.websock = new WebSocket(wsuri);
          this.isconnect = false;
          this.websock.onmessage = this.soketmessage;
        }else{
          //已连接
          console.log('已连接')
        }
      }, 1000*60);
      // this.websock.onerror = this.websock.onclose = () => {
      //   //重新链接
      //   if (this.isconnect === true) {
      //     return
      //   }
      //   this.isconnect = true;
      //   setTimeout(_ => {
      //     this.websock = new WebSocket(wsuri);
      //     this.isconnect = false;
      //   }, 2000)
      // }

    },
    soketmessage(message) {
      let result = JSON.parse(message.data);
      this.currentdata = result.data;
    },
    regioncountMessage(){
      regioncountMessage(this.getcid).then(res=>{
        console.log(res.data,'eeeeeeeeeeee')
        this.currentdata=res.data;
      })
    },
    mqttMSG() {
      const options = {
        connectTimeout: 40000,
        clientId: 'mqtitId-Home',
        username: "",
        password: "",
        reconnectPeriod: 1000 * 60 * 5, // 1000毫秒，两次重新连接之间的间隔
        // connectTimeout: 30 * 1000, // 1000毫秒，两次重新连接之间的间隔
        // resubscribe: true // 如果连接断开并重新连接，则会再次自动订阅已订阅的主题（默认true）
        clean: true
      }
      this.client = mqtt.connect(process.env.VUE_APP_BASE_SOKET + '/mqtt', options)
      if (this.client.connected) {
        this.client.end();
      }
      this.client.on("message", (topic, message) => {
        if('/xinbei/count'===topic){
          this.currentdata= JSON.parse(message.toString())
          console.log(topic,     this.currentdata,'eeeeeeeeeeeeeee');
        }

      })
      this.client.on("connect", e => {
        this.client.subscribe(
            ['/xinbei/count'],
            error => {
              if (!error) {
                console.log("订阅成功",error);
              } else {
                console.log("订阅失败");
              }
            });
      })
    },
    showPopup(type) {
      this.type = type
      this.getWaterInfo(type)
      this.popup_show = false
      this.mask_show = false
    },
    closePopup() {
      this.popup_show = false
      this.mask_show = false
    },
    async getWaterInfo(type) {
      this.waterData.info = []
      this.more = true
      this.pageNum = 1
      while (this.more) {
        let response = await waterInfo(type, this.pageNum, this.pageSize)
        this.waterData.st = response.data
        if (response.data.waterInfoVOS.length > 0 && this.pageNum <= 200) {
          this.waterData.info = this.waterData.info.concat(response.data.waterInfoVOS)
          this.more = true
          this.pageNum++
        } else {
          this.more = false
        }
      }
    },
    closeLongitudeLatitudedialogis() {
      this.$store.commit('updatelongitudeLatitudedialogisshow', false)
    },
    showdialog(type, title) {
      regionvillage(type,this.getcid).then((result) => {
        this.$refs["Alert"].showCunlist(result.data, title, type);
      })
    },
    // 产业面积趋势（亩）
    industrialArea() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(this.$refs["echarts-1"]);

      var option = {
        //  图表距边框的距离,可选值：'百分比'¦ {number}（单位px）
        grid: {
          top: "8%", // 等价于 y: '16%'
          left: "0",
          right: "5%",
          bottom: "0",
          borderWidth: 0,
          containLabel: true,
        },
        // 提示框
        tooltip: {
          trigger: "axis",
        },

        xAxis: {
          data: this.industrial.x,
          axisTick: {
            alignWithLabel: true
          },
          axisLine:{
            show:false,
            lineStyle:{
              color:'rgba(66, 213, 255, 0.1)'
            }
          },
          axisLabel:{
            fontSize: 14,
            color:'#FFFFFF'
          },
        },
        yAxis: [
          {
            type: 'value',
            gridIndex: 0,
            min:0,
            splitLine:{
              show:false,
              lineStyle:{
                color:'rgba(66, 213, 255, 0.1)'
              }
            },
            axisTick: {
              show: false,
            },
            axisLine:{
              show:true,

              lineStyle:{
                color:'rgba(66, 213, 255, 0.1)'
              }
            },
            axisLabel:{
              fontSize: 12,
              color:'#FFFFFF',
              // formatter:(e)=>{
              //   return this.fengxiang[e.toString()]
              // },
            },
          },
        ],

        series: [
          {
            data: this.industrial.y,
            type: "line",
            symbol: 'roundRect',
            // 设置折线上圆点大小
            symbolSize: 6,
            smooth: true,
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(36, 147, 241, 0.69)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(28, 193, 240, 0)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(36, 147, 241, 1)",
            },
            itemStyle: {
              // 拐点上显示数值
              label: {
                show: true,
                color: "rgba(36, 147, 241, 1)",
              },

              lineStyle: {
                width: 5, // 设置线宽
                type: "solid", //'dotted'虚线 'solid'实线
              },
              borderColor: "rgba(36, 147, 241, 1)", // 拐点边框颜色
            },
          },
        ],
        color: ["rgba(36, 147, 241, 1)"],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    //产业排行
    industrialaAreaSort() {
      this.initHighCharts2()
      return
      let myChart = echarts.init(this.$refs["echarts-2"]);
      var option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "line", // 默认为直线，可选为：'line' | 'shadow'
          },
        },

        grid: {
          show: true,
          top: "8%", // 等价于 y: '16%'
          left: "0",
          right: "5%",
          bottom: "0",
          borderWidth: 0,
          containLabel: true,
          backgroundColor: "rgba(0,65,137,0.3)",
        },
        //  ------  X轴 ------
        xAxis: {
          show: true, // 是否显示
          position: "bottom", // x轴的位置
          offset: 0, // x轴相对于默认位置的偏移
          type: "category", // 轴类型， 默认为 'category'
          axisLabel: {
            // 坐标轴标签
            show: true, // 是否显示
            inside: false, // 是否朝内
            rotate: 0, // 旋转角度
            color: "#ffff", // 默认取轴线的颜色
            interval: 0,
          },
          splitLine: {
            // gird区域中的分割线
            show: false, // 是否显示
            lineStyle: {
              color: 'red',
              // width: 1,
              // type: 'solid'
            },
          },
          splitArea: {
            // 网格区域
            show: false, // 是否显示，默认为false
          },
          data: this.industrialsort.x,
        },
        //   ------   y轴  ----------
        yAxis: {
          show: true, // 是否显示
          offset: 0, // y轴相对于默认位置的偏移
          type: "value", // 轴类型，默认为 ‘category’
          axisLabel: {
            // 坐标轴的标签
            show: true, // 是否显示
            inside: false, // 是否朝内
            rotate: 0, // 旋转角度
            margin: 8, // 刻度标签与轴线之间的距离
            color: "#fff", // 默认轴线的颜色
          },
          splitLine: {
            // gird 区域中的分割线
            show: false, // 是否显示
          },
          splitArea: {
            // 网格区域
            show: false, // 是否显示，默认为false
          },
        },
        //  -------   内容数据 -------
        series: [
          {
            type: "bar", // 类型
            legendHoverLink: true, // 是否启用图列 hover 时的联动高亮
            label: {
              // 图形上的文本标签
              show: false,
              position: "insideTop", // 相对位置
              rotate: 0, // 旋转角度
              color: "#azure",
            },
            itemStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(124, 188, 246, 1)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(24, 141, 240, 1)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            barWidth: 16, // 柱形的宽度
            data: this.industrialsort.y,
          },
        ],
      };
      myChart.setOption(option);
    },
    //产业资源分布
    industrialResources() {
      let myChart = echarts.init(this.$refs["echarts-3"]);
      let options = {
        legend: {
          top: "5%", // bottom:"20%" // 组件离容器的距离
          right: "5%",
          textStyle: {
            // 图列内容样式
            color: "#fff", // 字体颜色
          },

          data: ["合作社", "企业", "种植大户"],
        },
        tooltip: {},
        grid: {
          top: "35%", // 等价于 y: '16%'
          left: "3%",
          right: "8%",
          bottom: "3%",
          containLabel: true,
        },
        dataset: {
          // 提供一份数据。
          source: this.resources,
        },
        // 声明一个 X 轴，类目轴（category）。默认情况下，类目轴对应到 dataset 第一列。
        xAxis: {
          type: "category",
          axisLabel: {
            // 坐标轴标签
            show: true, // 是否显示
            inside: false, // 是否朝内
            rotate: 0, // 旋转角度
            color: "azure", // 默认取轴线的颜色
            interval: 0,
          },
        },
        // 声明一个 Y 轴，数值轴。
        yAxis: {
          splitLine: {
            lineStyle: {
              color: "#1d3764",
            },
          },
          axisLine: {
            lineStyle: {
              // 设置y轴颜色
              type: "solid",
              color: "#EEEEF1", //左边线的颜色
              width: "10", //坐标线的宽度
            },
          },
        },
        // 声明多个 bar 系列，默认情况下，每个系列会自动对应到 dataset 的每一列。
        series: [
          {
            type: "bar",
            barWidth: 10, // 柱形的宽度
            barCategoryGap: "100%",
            barGap: '100%',
            itemStyle: {
              color: "rgba(8, 236, 242, 1)",
            },
          },
          {
            type: "bar",
            barWidth: 10, // 柱形的宽度
            barCategoryGap: "100%",
            barGap: '100%',
            itemStyle: {
              color: "rgba(255, 182, 59, 1)",
            },
          },
          {
            type: "bar",
            barWidth: 10, // 柱形的宽度
            barCategoryGap: "100%",
            barGap: '100%',
            itemStyle: {
              color: "rgba(73, 144, 255, 1)",
              // 拐点上显示数值

            },
          },
        ],
      };
      myChart.setOption(options);
    },

    //产业产量趋势（吨）
    yieldtrend() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(this.$refs["echarts-4"]);
      let option = {
        //  图表距边框的距离,可选值：'百分比'¦ {number}（单位px）
        grid: {
          top: "4%", // 等价于 y: '16%'
          left: "0",
          right: "5%",
          bottom: "0",
          containLabel: true,
          backgroundColor: "rgba(0,65,137,0.3)",
        },
        // 提示框
        tooltip: {
          trigger: "axis",
        },

        xAxis: {
          axisLine: {
            lineStyle: {
              // 设置x轴颜色
              type: "solid",
              color: "#1d3764",
              width: "1",
            },
          },
          axisLabel: {
            show: true,
            color: "azure",
            interval: 0, //设置X轴数据间隔几个显示一个，为0表示都显示
          },
          // boundaryGap值为false的时候，折线第一个点在y轴上
          // boundaryGap: false,
          data: this.yieldtrenddata.x,
          splitLine: {
            show: false,
            lineStyle: {
              color: "rgba(50,174,229,0.4)",
            },
          },
        },
        yAxis: {
          type: "value",
          // min: this.yieldtrenddata.min, // 设置y轴刻度的最小值
          // max: this.yieldtrenddata.max, // 设置y轴刻度的最大值
          //     splitNumber: 5, // 设置y轴刻度间隔个数
          axisLine: {
            show: false,
            lineStyle: {
              // 设置y轴颜色
              type: "solid",
              color: "#EEEEF1", //左边线的颜色
              width: "10", //坐标线的宽度
            },
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: "rgba(50,174,229,0.4)",
            },
          },
        },

        series: [
          {
            data: this.yieldtrenddata.y,
            type: "line",
            // 设置折线上圆点大小
            symbolSize: 4,
            smooth: true,
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(30, 144, 255，0.5)",
            },
            itemStyle: {
              // 拐点上显示数值
              label: {
                show: true,
                color: "azure",
              },

              lineStyle: {
                width: 3, // 设置线宽
                type: "rgba(0, 226, 173, 1)", //'dotted'虚线 'solid'实线
              },
              borderColor: "rgba(0, 226, 173, 1)", // 拐点边框颜色
            },
          },
        ],
        color: ["rgba(0, 226, 173, 1)"],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    //产业产值趋势（亿元）
    industrialValue() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(this.$refs["echarts-5"]);
      var option = {
        //  图表距边框的距离,可选值：'百分比'¦ {number}（单位px）
        grid: {
          show: true,
          top: "4%", // 等价于 y: '16%'
          left: "0",
          right: "5%",
          bottom: "0",
          borderWidth: 0,
          containLabel: true,
          // backgroundColor: "rgba(0,65,137,0.3)",
        },

        // 提示框
        tooltip: {
          trigger: "axis",
        },

        xAxis: {
          axisLine: {
            lineStyle: {
              // 设置x轴颜色
              type: "solid",
              color: "#1d3764",
              width: "1",
            },
          },
          axisLabel: {
            show: true,
            color: "azure",
            interval: 0, //设置X轴数据间隔几个显示一个，为0表示都显示
          },
          // boundaryGap值为false的时候，折线第一个点在y轴上
          // boundaryGap: false,
          data: this.industrialValuedata.x,
          splitLine: {
            show: false,
            lineStyle: {
              color: "rgba(50,174,229,0.4)",
            },
          },
        },
        yAxis: {
          type: "value",
          // min: this.industrialValuedata.min, // 设置y轴刻度的最小值
          // max: this.industrialValuedata.max, // 设置y轴刻度的最大值
          splitNumber: 5, // 设置y轴刻度间隔个数
          axisLine: {
            show: false,
            lineStyle: {
              // 设置y轴颜色
              type: "solid",
              color: "#EEEEF1", //左边线的颜色
              width: "10", //坐标线的宽度
            },
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: "rgba(50,174,229,0.4)",
            },
          },
        },

        series: [
          {
            data: this.industrialValuedata.y,
            type: "line",
            // 设置折线上圆点大小
            symbolSize: 4,
            smooth: true,
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(255, 182, 59, 1)",
            },
            itemStyle: {
              // 拐点上显示数值
              label: {
                show: true,
                color: "azure",
              },

              lineStyle: {
                width: 3, // 设置线宽
                type: "rgba(255, 182, 59, 1)", //'dotted'虚线 'solid'实线
              },
              borderColor: "rgba(255, 182, 59, 1)", // 拐点边框颜色
            },
          },
        ],
        color: ["rgba(255, 182, 59, 1)"],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    //产业销售占比
    industrialSales() {
      let myChart = echarts.init(this.$refs["echarts-6"]);
      let option = {
        title: {
          text: "", //主标题文本
          subtext: "虾蟹占比", //副标题文本
          left: "center",
          top: "44%",
          textStyle: {
            fontSize: 20,
            color: "azure",
            align: "center",
          },
          subtextStyle: {
            fontSize: 20,
            color: "azure",
            align: "center",
            fontWeight: 500,
          },
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}占比: {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          x: "80%",
          y: "75%",
          data: this.industrialSalesdata.map((v) => {
            return v["name"];
          }),
          textStyle: {
            color: "azure",
          },
        },
        emphasis: {
          scale: true,
          shadowBlur: 200, //阴影大小
          shadowColor: "rgba(0,0,0,0.5)", //阴影颜色
        },
        series: [
          {
            id: 'pie',
            type: "pie", // 设置图表类型为饼图
            avoidLabelOverlap: false,
            radius: ["40%", "65%"],
            center: ["50%", "50%"],
            colorBy: "series",
            emphasis:{
              scaleSize:9,
            },
            itemStyle: {
              //普通样式
              shadowBlur: 100, //阴影大小
              shadowOffsetX: 0, //阴影水平方向的偏移
              shadowOffsetY: 0, //阴影垂直方向的偏移
              shadowColor: "rgba(0,0,0,0.5)", //阴影颜色
              //高亮样式
            },
            label: {
              formatter: "{a|{b}:{d}%}",
              color: "azure",
              fontFamily: "PingFangSC-Regular",

              rich: {
                a: {
                  color: "azure",
                  fontSize:16,
                  lineHeight: 17,
                  align: "center",
                },
              },
            },
            labelLine: {
              show: true,
              length: 30,
              length2: 30,
              lineStyle: {
                width:3,
                // color: "rgba(255,255,255,0.5)",

              },
            },
            data: this.industrialSalesdata,
          },
        ],
      };
      myChart.setOption(option);
      this.pieAutoHighlight(myChart)
    },
    pieAutoHighlight(myChart) {
      const _self = this
      setInterval(function () {
        const dataLen = _self.industrialSalesdata.length
        // 取消高亮
        myChart.dispatchAction({type: 'downplay', seriesId: 'pie', dataIndex: _self.pieCurIndex})
        _self.pieCurIndex = (_self.pieCurIndex + 1) % dataLen
        myChart.dispatchAction({type: 'highlight', seriesId: 'pie',dataIndex: _self.pieCurIndex})
      }, 1500)
    },
    initHighCharts2() {
      const chartOptions = {
        chart: {
          type: 'column',
          backgroundColor: 'transparent',
          options3d: {
            enabled: false,
            alpha: 0,
            beta: 10,
            depth: 0,
            viewDistance: 0,      // 视图距离，它对于计算角度影响在柱图和散列图非常重要。此值不能用于3D的饼图
            frame: {                // Frame框架，3D图包含柱的面板，我们以X ,Y，Z的坐标系来理解，X轴与 Z轴所形成
              // 的面为bottom，Y轴与Z轴所形成的面为side，X轴与Y轴所形成的面为back，bottom、
              // side、back的属性一样，其中size为感官理解的厚度，color为面板颜色
              bottom: {
                size: 10
              },
              side: {
                size: 1,
                color: 'transparent'
              },
              back: {
                size: 1,
                color: 'transparent'
              }
            }
          },
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        plotOptions: {
          column: {
            maxPointWidth:10,
            depth: 0
          }
        },
        xAxis: {
          gridLineWidth: 0,
          labels: {
            style:{color:'#fff'},
            reserveSpace: true
          },
          categories: this.industrialsort.x
        },
        yAxis: {
          gridLineWidth: 0,
          labels: {
            style:{color:'#fff'},
            reserveSpace: true
          },
          title: {
            text: null
          }
        },
        series: [{
          name: '产业面积',
          data: this.industrialsort.y,
          color: '#389CF2'
        }],
        tooltip: {
          valueSuffix: '亩'
        },
      }
      Highcharts.chart(this.$refs['highcharts-2'], chartOptions)
    },
    initHighCharts3(datas) {

      /**
       *
       * 作者: GhostCat
       * 博客: https://gcat.cc
       * 描述: 双立体折线辉光图
       *
       */
      let categories = []
      let brands = []
      let cooperatives = []
      let largeBreeds = []
      datas.map(i=>{
          categories.push(i[0])
          brands.push(i[1])
          cooperatives.push(i[2])
          largeBreeds.push(i[3])
      })
      // datas.forEach((v) => {
      //   categories.push(Object.values(v)[0])
      //   brands.push(Object.values(v)[1])
      //   cooperatives.push(Object.values(v)[2])
      //   largeBreeds.push(Object.values(v)[3])
      // })

      let dom = 300;
      let barWidth = dom / 20;
      let manColors = [];
      let womanColors = [];
      let womanColors1 = [];
      for (let i = 0; i < 10; i++) {
        manColors.push({
          type: 'linear',
          x: 0,
          x2: 1,
          y: 0,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: '#13e1e3', // 最左边
            },
            {
              offset: 0.5,
              color: '#13e1e3', // 左边的右边 颜色
            },
            {
              offset: 0.5,
              color: '#0f8f91', // 右边的左边 颜色
            },
            {
              offset: 1,
              color: '#0f8f91',
            },
          ],
        });
        womanColors.push({
          type: 'linear',
          x: 0,
          x2: 1,
          y: 0,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: '#ffa349', // 最左边
            },
            {
              offset: 0.5,
              color: '#ffa349', // 左边的右边 颜色
            },
            {
              offset: 0.5,
              color: '#f37a03', // 右边的左边 颜色
            },
            {
              offset: 1,
              color: '#f37a03',
            },
          ],
        });
        womanColors1.push({
          type: 'linear',
          x: 0,
          x2: 1,
          y: 0,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: '#66ceff', // 最左边
            },
            {
              offset: 0.5,
              color: '#66ceff', // 左边的右边 颜色
            },
            {
              offset: 0.5,
              color: '#0d81d7', // 右边的左边 颜色
            },
            {
              offset: 1,
              color: '#0d81d7',
            },
          ],
        });
      }
      let option = {
        // backgroundColor: '#010d3a',
        //提示框
        tooltip: {
          trigger: 'axis',
          formatter: function (p) {
            return `
                ${p[0].name}
                <br/>
                ${p[0].seriesName}:${p[0].value}
                <br/>
                ${p[3].seriesName}:${p[3].value}
                <br/>
                ${p[6].seriesName}:${p[6].value}
            `;
          },
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
          },
        },

        /**区域位置*/
        grid: {
          left: '10%',
          right: '4%',
          top: '40%',
          bottom: '20%',
        },
        //X轴
        xAxis: {
          data: categories,
          type: 'category',

          axisLine: {
            show: false,
            lineStyle: {
              color: 'rgba(255,255,255,1)',
              shadowColor: 'rgba(255,255,255,1)',
              shadowOffsetX: '20',
            },
            symbol: ['none', 'arrow'],
            symbolOffset: [0, 25],
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
          //   margin: 30,
            fontSize: 15,
          },
        },
        yAxis: {
          show: true,
          splitNumber: 4,
          axisLine: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#075858',
            },
          },
          axisLabel: {
            color: '#FFFFFF',
            margin: 30,
            fontSize: 15,
          },
        },
        series: [
          {
            name: '合作社',
            type: 'bar',
            barWidth: barWidth,
            itemStyle: {
                color: function (params) {
                  return manColors[params.dataIndex % 7];
                },
            },
            data: brands,
          },
          {
            z: 2,
            type: 'pictorialBar',
            data: brands,
            symbol: 'diamond',
            symbolOffset: ['-150%', '50%'],
            symbolSize: [barWidth, barWidth * 0.5],
            itemStyle: {
                color: function (params) {
                  return manColors[params.dataIndex % 7];
                },
            },
          },
          {
            z: 3,
            type: 'pictorialBar',
            symbolPosition: 'end',
            data: brands,
            symbol: 'diamond',
            symbolOffset: ['-150%', '-50%'],
            symbolSize: [barWidth, barWidth * 0.5],
            itemStyle: {
                borderWidth: 0,
                color: function (params) {
                  return manColors[params.dataIndex % 7].colorStops[0].color;
                },
            },
          },
          // ---------------------分割线---------------------
          {
            name: '企业',
            type: 'bar',
            barWidth: barWidth,
            itemStyle: {
                color: function (params) {
                  return womanColors[params.dataIndex % 7];
                },
            },
            data: cooperatives,
          },
          {
            z: 2,
            type: 'pictorialBar',
            data: cooperatives,
            symbol: 'diamond',
            symbolOffset: ['0%', '50%'],
            symbolSize: [barWidth, barWidth * 0.5],
            itemStyle: {
                color: function (params) {
                  return womanColors[params.dataIndex % 7];
                },
            },
          },
          {
            z: 3,
            type: 'pictorialBar',
            symbolPosition: 'end',
            data: cooperatives,
            symbol: 'diamond',
            symbolOffset: ['0%', '-50%'],
            symbolSize: [barWidth, barWidth * 0.5],
            itemStyle: {
                borderWidth: 0,
                color: function (params) {
                  return womanColors[params.dataIndex % 7].colorStops[0].color;
                },
            },
          },

          // ---------------------分割线---------------------
          {
            name: '种植大户',
            barGap:"50%",
            type: 'bar',
            barWidth: barWidth,
            itemStyle: {
              color: function (params) {
                return womanColors1[params.dataIndex % 7];
              },
            },
            data: largeBreeds,
          },
          {
            z: 2,
            type: 'pictorialBar',
            data: largeBreeds,
            symbol: 'diamond',
            symbolOffset: ['150%', '50%'],
            symbolSize: [barWidth, barWidth * 0.5],
            itemStyle: {
              color: function (params) {
                return womanColors1[params.dataIndex % 7];
              },
            },
          },
          {
            z: 3,
            type: 'pictorialBar',
            symbolPosition: 'end',
            data: largeBreeds,
            symbol: 'diamond',
            symbolOffset: ['150%', '-50%'],
            symbolSize: [barWidth, barWidth * 0.5],
            itemStyle: {
              borderWidth: 0,
              color: function (params) {
                return womanColors1[params.dataIndex % 7].colorStops[0].color;
              },
            },
          },
        ],
      };
      const myChart = echarts.init(this.$refs['highcharts-3'])
      myChart.setOption(option);
      return ;

      // let categories = []
      // let brands = []
      // let cooperatives = []
      // let largeBreeds = []

      datas.forEach((v) => {
        categories.push(Object.values(v)[0])
        brands.push(Object.values(v)[1])
        cooperatives.push(Object.values(v)[2])
        largeBreeds.push(Object.values(v)[3])
      })

      const series = [
        {
          name: '合作社',
          color: '#08ECF2',
          data: brands
        },
        {
          name: '企业',
          color: '#FFB63B',
          data: cooperatives,
        },
        {
          name: '种植大户',
          color: '#4990FF',
          data: largeBreeds,
        },
      ]

      const chartOptions = {
        chart: {
          type: 'column',
          backgroundColor: 'transparent',
          options3d: {
            enabled: true,
            alpha: 0,
            depth: 1
          }
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        xAxis: {
          labels: {
            style: {
              color: '#fff'
            }
          },
          gridLineWidth: 0,
          categories: categories
        },
        yAxis: {
          labels: {
            style: {
              color: '#fff'
            }
          },
          gridLineWidth: 0,
          allowDecimals: false,
          title: {
            text: ''
          }
        },
        tooltip: {
          headerFormat: '<b>{point.key}</b><br>',
          pointFormat: '<span style="color:{series.color}">\u25CF</span> {series.name}: {point.y}'
        },
        plotOptions: {
          column: {
            depth: 15,
            edgeWidth:0.1,
            pointWidth:15,
          }
        },
        legend: {
          align: 'right',
          verticalAlign: 'top',
          layout: 'horizontal',
          className: 'industrial-legend',
          itemStyle: {
            color: '#fff'
          },
          itemHoverStyle: {
            color: '#ccc'
          },
          itemMarginTop: 1
        },
        series: series
      }

      this.chartOptions = {
        chart: {
          type: 'column',
          backgroundColor: 'transparent',
          options3d: {
            enabled: true,
            alpha: 5,
            depth: 40
          }
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        xAxis: {
          labels: {
            style: {
              color: '#fff'
            }
          },
          gridLineWidth: 0,
          categories: categories
        },
        yAxis: {
          labels: {
            style: {
              color: '#fff'
            }
          },
          gridLineWidth: 0,
          allowDecimals: false,
          title: {
            text: ''
          },
        },
        tooltip: {
          headerFormat: '<b>{point.key}</b><br>',
          pointFormat: '<span style="color:{series.color}">\u25CF</span> {series.name}: {point.y}'
        },
        plotOptions: {
          column: {
            // stacking: 'normal',
            depth: 40
          }
        },
        legend: {
          align: 'right',
          verticalAlign: 'top',
          layout: 'horizontal',
          itemStyle: {
            color: '#fff'
          },
          itemHoverStyle: {
            color: '#ccc'
          },
          itemMarginTop: '-5px'
        },
        series: series
      }

      console.log('chartOptions', chartOptions)

      Highcharts.chart(this.$refs['highcharts-3'], chartOptions);
    }
  },
  computed: {
    ...mapState({
      zhenlist: state =>state.zhenlist
    }),
    cname() {
      return this.$route.params.cname;
    },
    getcid(){
      let areaId=0;
      this.zhenlist.map(i=>{
        if (i.areaName===this.cname){
          areaId=i.areaId
        }
      })
      return areaId;
    },
  },
  components: {
    Industrialmap,
    Alert,
    staticText,
    staticpie,
    wsMask
  },
};
</script>
<style scoped lang="scss">

@function w($width) {
  @return $width;
}

@function h($height) {
  @return $height;
}

.industrial {
  width: 100%;
  height: 100%;
  //background-image: url("~@/assets/images/icon/bg.jpg");
  //background-size: 100% 100%;

  .industrial-main {
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 436px;
    margin: h(7px) auto h(21px) auto;

    .industrial-main-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 140px;
      height: h(71px);
      //background: #06316F;
      cursor: pointer;
      margin-top: h(16px);

      .industrial-main-item-icon {
        width: 52.5%;
        height: 59.3%;
      }

      .industrial-main-item-body {
        width: 56.32%;
        height: 70%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: left;

        .industrial-main-item-body-title {
          width: 80px;
          min-height: 48%;
          font-size: 0.9rem;
          transform: scale(0.9);
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #FFFFFF;
          margin: 0;
        }

        .industrial-main-item-body-number {
          margin: 0;

          span {
            font-family: Arial;
            font-weight: 400;
            font-size: 0.9rem;
            color: #fff;

            &:first-child {
              font-size: 1.6rem;
              color: #08FCFF;
            }
          }
        }
      }
    }
  }
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: url("~@/assets/images/icon/f_popup_bg.png");
    background-size: 100% 100%;
    z-index: 1000;

    .popup-close {
      padding: 12px;
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;

      img {
        width: 18px;
      }
    }

    .popup-title {
      width: 526px;
      height: h(66px);
      background: url("~@/assets/images/icon/f_popup_title_bg.png");
      background-size: 100% 100%;
      margin: h(42px) auto 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        height: 1.5rem;
        line-height: 1.5rem;
        font-size: 1.5rem;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
      }
    }

    .popup-contriner {
      padding: 0 50px h(60px) 50px;

      .popup-standard {
        display: flex;
        font-size: 1rem;
        font-family: Source Han Sans CN;
        font-weight: 500;
        margin-top: h(25px);

        .popup-standard-title {
          color: #FFFFFF;
        }

        .popup-standard-content {
          span {
            color: #1CFEFF;
            padding-right: 20px;
          }
        }
      }

      .popup-table {
        margin: h(35px) auto 0 auto;

        .popup-table-th {
          display: flex;

          .popup-table-th-td {
            font-size: 1rem;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #1CFEFF;
            height: h(42px);
            line-height: h(42px);
            border: 1px solid rgba(255, 255, 255, 0.2);
            border-right: none;
            border-bottom: none;
            text-align: center;
            background: #0075A9;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &:last-child {
              border-right: 1px solid rgba(255, 255, 255, 0.2);
            }
          }
        }

        .popup-table-body {
          height: h(253px);
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 0 !important;
          }

          .swiper {
            width: 100%;
            height: 100%;
            .popup-table-tr {
              display: flex;

              &:last-child {
                .popup-table-tr-td {
                  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                }
              }

              .popup-table-tr-td {
                font-size: 1rem;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #FFFFFF;
                height: h(42px);
                line-height: h(42px);
                border: 1px solid rgba(255, 255, 255, 0.2);
                border-right: none;
                border-bottom: none;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                &.n {
                  color: #FF3D3D;
                }

                &:last-child {
                  border-right: 1px solid rgba(255, 255, 255, 0.2);
                }
              }
            }
            .swiper-slide {
              height: auto !important;
            }
          }

          .swiper-container .swiper-wrapper{
            -webkit-transition-timing-function: linear; /*之前是ease-out*/
            -moz-transition-timing-function: linear;
            -ms-transition-timing-function: linear;
            -o-transition-timing-function: linear;
            transition-timing-function: linear;
          }

        }
      }
    }
  }
  .static{
    width: 370px;
    margin-left: 10px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;


    .static-l{
      width: 200px;
      height: 200px;
      position: relative;

      @keyframes turn {
        0% {
          -webkit-transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
        }
      }
      .images{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto auto;
        animation: turn 3s linear infinite;
        //transform: translate(-50%,-50%);
        max-width:60%;
      }
    }
    .static-r{
      width: 177px;
      height: h(140px);
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
  }
  .contant {
    position: relative;
    height: 89%;
    width: 100%;
    .contant-xz {
      position: absolute;
      bottom: 20%;
    }
  }
  .height11 {
    width: 100%;
    height: auto;
    .h-content {
      width: 100%;
      height: 87%;
      text-align: center;
    }
  }
  .height22{
    height: 31.35%;
    .h-content {
      height: 87%;
      padding: 1rem 0;
      text-align: center;
      margin-left: 30px;
    }
  }
  .height33 {
    //width: 88%;
    height: 32.5%;
    margin: 0 auto;
  }

  .height33 > .h-content {
    height: 87%;
    padding: 1rem 0;
    text-align: center;
  }

  .contant-left-l {
    width: 485px;
    height: 100%;
    position: absolute;
    //background: url("~@/assets/images/icon/右框 拷贝@2x.png") no-repeat;
    left: 10px;
    background-size: 100% 100%;
    top: -2%;
  }

  .contant-center-c {
    width: 952px;
    height: 100%;
    margin: auto 0 0 493px;
  }

  .contant-right-r {
    width: 468px;
    height: 100%;
    position: absolute;
    padding:27px 20.99px 0 28px;
    top: -2%;
    //background: url("~@/assets/images/icon/右框 拷贝 2@2x.png") no-repeat;
    background-size: 100% 100%;
    right: 10px;
  }

  .contant-left-l .height33:last-child > .h-content {
    padding-bottom: 0;
  }

  .height33 > .h-content ul {
    padding: 0;
    margin: 0;
  }

  .height33 > .h-content li {
    cursor: pointer;
    width: 30%;
    height: 29.8%;
    list-style: none;
    float: left;
    background-color: #06316f;
    margin-bottom: 3%;
    position: relative;
    padding: 0 16px;
  }

  .height33 > .h-content li img {
    width: 42px;
    height: 42px;
    margin-left: -60%;
    margin-top: 11%;
    display: inline-block;
  }

  .height33 > .h-content li > div {
    text-align: left;
    font-size: 0.8rem;
    color: azure;
    display: inline-block;
    margin-left: 10px;
  }

  .h-name {
    position: absolute;
    line-height: 1rem;
    top: 10%;
    font-size: 12px;
    left: 45%;
    white-space: nowrap;
  }

  .selectyear {
    position: relative !important;
    margin-top: -1.8rem;
  }

  .h-quantity {
    position: absolute;
    top: 50%;
    left: 45%;
  }

  .h-quantity > span {
    font-size: 2rem;
    font-family: Arial;
    font-weight: 400;
    color: #08fcff;
    line-height: 20px;
  }

  .margin1 {
    margin-left: 4%;
    margin-right: 4%;
  }

  .contant-center-top {
    width: 80%;
    height: 9%;
    //margin-top: 1%;
    margin:0 auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    text-align: center;
    top: 0;
    .c_c_t_div {
      background: url("~@/assets/images/icon/组23.png") no-repeat;
      background-size: 100% 100%;
      width: 25%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 1rem;
      > div{
        line-height:calc(100% + 2px);
        &:nth-child(2) {
          color: rgba(0, 181, 237, 1);
          font-size: 1.5rem;
        }
      }
    }
  }


  .contant-center-map {
    width: 100%;
    position: relative;
    height: 65.7%;
  }

  .contant-center-button {
    //width: 810px;
    height: 23%;
    //padding: 1rem 1rem 0 1rem;
    //background: url("~@/assets/images/icon/cbg.png") no-repeat;
    background-size: 100% 100%;
    width: 916px;
    margin: auto;
    position: relative;

    .h-title {
      position: relative;
      top:8%;
      left: 15px;
      font-size: 18px;
      height: 17.785% !important;
    }
    .h-center-content {
      width: 916px;
      height: 100%;
      position: relative;
      margin: auto;
      .highcharts_3_legend{
        position: absolute;
        width: 200px;
        right: 0;
        top: 13%;
        display: flex;
        flex-wrap: nowrap;
        font-size: 12px;
        align-items: center;
        >div{
          width: 10px;
          height: 10px;
          margin: 5px;
          &:nth-child(1){
            background-color: #13e1e3;
          }
          &:nth-child(2){
            background-color: #ffa349;
          }
          &:nth-child(3){
            background-color: #66ceff;
          }
        }
      }
      .highcharts-3 {
        width: 100%;
        height: 100%;
      }
    }
    .qrcode{
      width: 5.06vw;
      position: absolute;
      height: 6.70vw;
      bottom: 110%;
      left: 1%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .img5 {
        width: 5.27vw;
        height: 5.21vw;
      }
      .txt34 {
        left: 26.88vw;
        top: 33.39vw;
        width: 100%;
        height: 0.84vw;
        color: #FFFFFF;
        overflow-wrap: break-word;
        font-size: 0.83vw;
        font-family: HiraginoSansGB-W3;
        text-align: center;
        white-space: nowrap;
        line-height: 0.84vw;
        display: block;
      }
    }
  }


  .h-title .right {
    height: 100%;
    display: inline-flex;
    float: right;
  }

  .h-title .right select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    height: 2.2em;
    padding: 0 24px 0 8px;

    font-family: inherit;
    color: azure;
    cursor: pointer;
    position: relative;
    /*background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAASFBMVEUAAAD////Nzc3Nzc3V1dXNzc3MzMzMzMzMzMzNzc3Ozs7j4+PMzMzMzMzMzMzPz8/MzMzNzc3Ozs7Ozs7MzMzNzc3Nzc3Nzc1mbvnCAAAAGHRSTlMAAymOBrtVs9RlPgnPltxPlWwvRJzBt+CSuXutAAAAM0lEQVR4nGMYBbgALycjiGIUZEaTEBLl4WJgEOZgZcLQwybOLsbHj800bhEBFqo7cfACACvdARau8cpxAAAAAElFTkSuQmCC")*/
    /*no-repeat right center;*/
    background-color: #0b1a4d;
  }



}
</style>

<style lang="scss">
.industrial {
  .industrial-legend {

  }
}

</style>
