<template>
  <div class="ws-mask" :style="'z-index:' + zIndex" v-if="show"></div>
</template>

<script>
export default {
  name: "WsMask",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    zIndex: {
      type: String,
      default: 1
    }
  }
}
</script>

<style scoped lang="scss">
.ws-mask {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #000000;
  opacity: 0.7;
}
</style>