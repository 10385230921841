<template>
  <div class="static-text">
    <div class="static_back" :style="{borderColor:color}">
      <div class="qiu" :style="{backgroundColor:color}"></div>
      <div class="xian" :style="{backgroundColor:color}"></div>
    </div>
    <div class="text">
      <div>{{title}}</div>
      <div>{{number}}条</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "static-text",
  props:['color' ,'title','number']
};
</script>

<style scoped lang="scss">
@function w($width) {
  @return calc(100vw / (1920px) * $width);
}

@function h($height) {
  @return calc(100vh / (1080px) * $height);
}
.static-text{
  width: 210px;
  height: 25px;
  position: relative;
  cursor: pointer;
  .static_back{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50%;
    border-left: 1px solid #228FFE;
    border-bottom: 1px solid #228FFE;
    border-right: none;
    border-top: none;
    .qiu{
      width: 7.5px;
      height: 7.5px;

      background-color: #228FFE;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 50%;
      transform: translate(-50%,-50%);
    }
    .xian{
      width: 8px;
      height: 3px;
      background: #228FFE;
      position: absolute;
      right: 0;
      top: 100%;
      border-radius: 50%;
      transform: translate(0,-50%);
    }
  }
  .text{
    width: 200px;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    margin-top: h(-6px);
    justify-content: space-between;
    align-items: flex-end;
    >div{
      font-size: 13px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 100%;
      &:nth-child(2){
        font-size: 20px;
        color: #00F8FF;
        line-height: 20px;
      }
    }
  }
}
</style>
