<template>
  <div class="_alert"
       v-show="visible">
    <div class="wind-alert">
      <div class="wind-alert-bg"></div>
      <div class="wind-alert-dialog animate-scale" :class="{h2:diseasemanual&&diseasemanual.totalPage>1}">
        <div v-if="title!==''"
             class="wind-alert-title">{{ title }}
          <div @click="close"
               class="close"></div>
        </div>
        <div v-if="content!==''"
             class="wind-alert-content">{{ content }}
        </div>
        <!--        <div v-if="industrydata.length!==0"-->
        <!--             class="wind-alert-content-list">-->
        <div class="ccc" v-if="type==1">
          <div v-for="(item,k ) in industrydata"
               :key="k"
               class="villagelist" :class="{mtn: k <= 2}">{{ item.name }}
          </div>
          <div v-if="industrydata&&industrydata.length%3!==0"
               v-for="i  in 3-industrydata.length%3"
               :key="i"
               class="villagelist kongbai" >&nbsp;
          </div>
        </div>
        <div v-if="type==2||
          type==3||
          type==4||
          type==5||
          type==6||type==7"
             class="dialog-list">
          <el-row class="tableh"
                  v-if="type==2">
            <el-col class="t1">合作社名称</el-col>
            <el-col class="t2">所属村庄</el-col>
            <el-col class="t2">法人姓名</el-col>
            <el-col class="t2">联系方式</el-col>
          </el-row>
          <el-row class="tableh"
                  v-if="type==3">
            <el-col class="t1">养殖大户</el-col>
            <el-col class="t2">所属村庄</el-col>
            <el-col class="t2">法人姓名</el-col>
            <el-col class="t2">联系方式</el-col>
          </el-row>
          <el-row class="tableh"
                  v-if="type==4">
            <el-col class="t1">合作名称</el-col>
            <el-col class="t2">所属村庄</el-col>
            <el-col class="t2">法人姓名</el-col>
            <el-col class="t2">联系方式</el-col>
          </el-row>
          <el-row class="tableh"
                  v-if="type==5">
            <el-col class="t1">品牌名称</el-col>
            <el-col class="t2">所属村庄</el-col>
            <el-col class="t2">法人姓名</el-col>
            <el-col class="t2">联系方式</el-col>
          </el-row>
          <el-row class="tableh"
                  v-if="type==6">
            <el-col class="t1">企业名称</el-col>
            <el-col class="t2">所属村庄</el-col>
            <el-col class="t2">法人姓名</el-col>
            <el-col class="t2">联系方式</el-col>
          </el-row>
          <el-row class="tableh"
                  v-if="type==7">
            <el-col class="t1">基地名称</el-col>
            <el-col class="t2">所属村庄</el-col>
            <el-col class="t2">法人姓名</el-col>
            <el-col class="t2">联系方式</el-col>
          </el-row>
          <div class="fx">
            <el-row class="tb"
                    v-for="(item,k ) in industrydata"
                    :key="k">
              <el-col class="t1">{{ item.name }}</el-col>
              <el-col class="t2">{{ item.affiliatedUnit }}</el-col>
              <el-col class="t2">{{ item.ceoName }}</el-col>
              <el-col class="t2">{{ item.phone }}</el-col>
            </el-row>
          </div>

        </div>
        <div class="cc" v-if="industrydata.length!==0 && (type==8 || type==9)">
          <div class="njzj" v-if="type==8 || type==9">
            <div class="njzj-item" :class="{mtn: index <= 2}" v-for="(item, index) in industrydata" :key="index">
              <img class="njzj-item-avatar" :src="item.image">
              <div class="njzj-item-r">
                <p class="t">
                  <span>{{ item.name }}</span>
                  <span>{{ item.title }}</span>
                </p>
                <p class="b">{{ item.colleges }}</p>
              </div>
            </div>
            <div class="njzj-item mtn noback"  v-if="industrydata.length===2" ></div>
            <div class="njzj-item mtn noback"  v-if="industrydata.length===1" ></div>
            <div class="njzj-item mtn noback"  v-if="industrydata.length===1" ></div>
          </div>
        </div>




      <div v-if="diseaseData&&diseaseData.diseaseName"
           class="wind-alert-content-disease" >
        <div class="tag">
          <div @click="diseaseIndex=1"
               :class="{tag_item:true,show:diseaseIndex==1}">基本信息
          </div>
          <div @click="diseaseIndex=2"
               :class="{tag_item:true,show:diseaseIndex==2}">危害情况
          </div>
          <div @click="diseaseIndex=3"
               :class="{tag_item:true,show:diseaseIndex==3}">防治方法
          </div>
        </div>

        <div v-if="diseaseIndex==1"
             class="tag_content">
          <img class="diseas_img"
               :src="diseaseData.imageUrl"/>
          <div class="diseas_message">
            <div>{{ diseaseData.diseaseBaseInfo }}</div>
            <div>
              【常见病因】<br/>
              {{ diseaseData.diseaseBaseInfoCause }}
            </div>
            <div>
              【常见症状】<br/>
              {{ diseaseData.diseaseBaseInfoSymptoms }}
            </div>
          </div>
        </div>
        <div v-if="diseaseIndex==1"
             STYLE="font-size: 16px;">
          【建议方案】 {{ diseaseData.diseaseSuggest }}
        </div>
        <div v-if="diseaseIndex==2"
             class="tag_content">
          {{ diseaseData.diseaseHarmInfo }}
        </div>
        <div v-if="diseaseIndex==3"
             class="tag_content">
          {{ diseaseData.diseasePreventionControlWay }}
        </div>
      </div>
      <div v-if="diseasemanual&&diseasemanual.totalPage>1" class="page">
        <div v-if="diseasemanual.pageNum>1" @click="gopage(diseasemanual.pageNum-1)">上一条</div>
        <div v-if="diseasemanual.pageNum<diseasemanual.totalPage" @click="gopage(diseasemanual.pageNum+1)">下一条</div>
        <div @click="gopage(1)">最新上报</div>
      </div>

      <div v-if="diseaseinformationList" class="history">
        <div>
          <div>发生面积</div>
          <div><span>{{ diseaseinformationList.occurrenceArea }}</span>亩</div>
        </div>
        <div>
          <div>成灾面积</div>
          <div><span>{{ diseaseinformationList.inundatedArea }}</span>亩</div>
        </div>
        <div>
          <div>防治面积</div>
          <div><span>{{ diseaseinformationList.preventionControlArea }}</span>亩</div>
        </div>
        <div>
          <div>死蟹数量</div>
          <div><span>{{ diseaseinformationList.deadCrabNumber }}</span>只</div>
        </div>
        <div>
          <div>总发生率</div>
          <div><span>{{ diseaseinformationList.occurrenceRate }}</span></div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
export default {
  name: "alert",
  data() {
    return {
      content: '',
      //资源管理
      type: 0,
      industrydata: [],
      //病虫
      diseaseData: {},
      diseaseIndex: 1,
      //病蟲害人工
      diseasemanual: {},
      callback: null,
      title: "",
      btn: '',
      visible: false,
      diseaseinformationList: null,
    }
  },
  methods: {
    showCunlist(list, title, type) {
      this.industrydata = list;
      // this.industrydata = [
      //   {
      //     contactWay: "123",
      //     imageUrl: "http://106.14.25.175:9002/touxiang.jpg",
      //     industrySubjectName: "专家",
      //     legalPersonName: "陈远良",
      //     schoolTag: "新疆农业职业技术学院",
      //     villageName: "桥西村",
      //   },
      //   {
      //     contactWay: "123",
      //     imageUrl: "http://106.14.25.175:9002/touxiang.jpg",
      //     industrySubjectName: "专家",
      //     legalPersonName: "陈远良",
      //     schoolTag: "新疆农业职业技术学院",
      //     villageName: "桥西村",
      //   },
      //   {
      //     contactWay: "123",
      //     imageUrl: "http://106.14.25.175:9002/touxiang.jpg",
      //     industrySubjectName: "专家",
      //     legalPersonName: "陈远良",
      //     schoolTag: "新疆农业职业技术学院",
      //     villageName: "桥西村",
      //   },
      //   {
      //     contactWay: "123",
      //     imageUrl: "http://106.14.25.175:9002/touxiang.jpg",
      //     industrySubjectName: "专家",
      //     legalPersonName: "陈远良",
      //     schoolTag: "新疆农业职业技术学院",
      //     villageName: "桥西村",
      //   },
      //   {
      //     contactWay: "123",
      //     imageUrl: "http://106.14.25.175:9002/touxiang.jpg",
      //     industrySubjectName: "专家",
      //     legalPersonName: "陈远良",
      //     schoolTag: "新疆农业职业技术学院",
      //     villageName: "桥西村",
      //   },
      //   {
      //     contactWay: "123",
      //     imageUrl: "http://106.14.25.175:9002/touxiang.jpg",
      //     industrySubjectName: "专家",
      //     legalPersonName: "陈远良",
      //     schoolTag: "新疆农业职业技术学院",
      //     villageName: "桥西村",
      //   },
      //   {
      //     contactWay: "123",
      //     imageUrl: "http://106.14.25.175:9002/touxiang.jpg",
      //     industrySubjectName: "专家",
      //     legalPersonName: "陈远良",
      //     schoolTag: "新疆农业职业技术学院",
      //     villageName: "桥西村",
      //   },
      //   {
      //     contactWay: "123",
      //     imageUrl: "http://106.14.25.175:9002/touxiang.jpg",
      //     industrySubjectName: "专家",
      //     legalPersonName: "陈远良",
      //     schoolTag: "新疆农业职业技术学院",
      //     villageName: "桥西村",
      //   },
      //   {
      //     contactWay: "123",
      //     imageUrl: "http://106.14.25.175:9002/touxiang.jpg",
      //     industrySubjectName: "专家",
      //     legalPersonName: "陈远良",
      //     schoolTag: "新疆农业职业技术学院",
      //     villageName: "桥西村",
      //   }, {
      //     contactWay: "123",
      //     imageUrl: "http://106.14.25.175:9002/touxiang.jpg",
      //     industrySubjectName: "专家",
      //     legalPersonName: "陈远良",
      //     schoolTag: "新疆农业职业技术学院",
      //     villageName: "桥西村",
      //   },
      //   {
      //     contactWay: "123",
      //     imageUrl: "http://106.14.25.175:9002/touxiang.jpg",
      //     industrySubjectName: "专家",
      //     legalPersonName: "陈远良",
      //     schoolTag: "新疆农业职业技术学院",
      //     villageName: "桥西村",
      //   },
      //   {
      //     contactWay: "123",
      //     imageUrl: "http://106.14.25.175:9002/touxiang.jpg",
      //     industrySubjectName: "专家",
      //     legalPersonName: "陈远良",
      //     schoolTag: "新疆农业职业技术学院",
      //     villageName: "桥西村",
      //   },
      //   {
      //     contactWay: "123",
      //     imageUrl: "http://106.14.25.175:9002/touxiang.jpg",
      //     industrySubjectName: "专家",
      //     legalPersonName: "陈远良",
      //     schoolTag: "新疆农业职业技术学院",
      //     villageName: "桥西村",
      //   },
      //   {
      //     contactWay: "123",
      //     imageUrl: "http://106.14.25.175:9002/touxiang.jpg",
      //     industrySubjectName: "专家",
      //     legalPersonName: "陈远良",
      //     schoolTag: "新疆农业职业技术学院",
      //     villageName: "桥西村",
      //   },
      //   {
      //     contactWay: "123",
      //     imageUrl: "http://106.14.25.175:9002/touxiang.jpg",
      //     industrySubjectName: "专家",
      //     legalPersonName: "陈远良",
      //     schoolTag: "新疆农业职业技术学院",
      //     villageName: "桥西村",
      //   },
      //   {
      //     contactWay: "123",
      //     imageUrl: "http://106.14.25.175:9002/touxiang.jpg",
      //     industrySubjectName: "专家",
      //     legalPersonName: "陈远良",
      //     schoolTag: "新疆农业职业技术学院",
      //     villageName: "桥西村",
      //   },
      //   {
      //     contactWay: "123",
      //     imageUrl: "http://106.14.25.175:9002/touxiang.jpg",
      //     industrySubjectName: "专家",
      //     legalPersonName: "陈远良",
      //     schoolTag: "新疆农业职业技术学院",
      //     villageName: "桥西村",
      //   },
      //   {
      //     contactWay: "123",
      //     imageUrl: "http://106.14.25.175:9002/touxiang.jpg",
      //     industrySubjectName: "专家",
      //     legalPersonName: "陈远良",
      //     schoolTag: "新疆农业职业技术学院",
      //     villageName: "桥西村",
      //   },
      //   {
      //     contactWay: "123",
      //     imageUrl: "http://106.14.25.175:9002/touxiang.jpg",
      //     industrySubjectName: "专家",
      //     legalPersonName: "陈远良",
      //     schoolTag: "新疆农业职业技术学院",
      //     villageName: "桥西村",
      //   },
      //   {
      //     contactWay: "123",
      //     imageUrl: "http://106.14.25.175:9002/touxiang.jpg",
      //     industrySubjectName: "专家",
      //     legalPersonName: "陈远良",
      //     schoolTag: "新疆农业职业技术学院",
      //     villageName: "桥西村",
      //   },
      //   {
      //     contactWay: "123",
      //     imageUrl: "http://106.14.25.175:9002/touxiang.jpg",
      //     industrySubjectName: "专家",
      //     legalPersonName: "陈远良",
      //     schoolTag: "新疆农业职业技术学院",
      //     villageName: "桥西村",
      //   },
      //   {
      //     contactWay: "123",
      //     imageUrl: "http://106.14.25.175:9002/touxiang.jpg",
      //     industrySubjectName: "专家",
      //     legalPersonName: "陈远良",
      //     schoolTag: "新疆农业职业技术学院",
      //     villageName: "桥西村",
      //   },
      //   {
      //     contactWay: "123",
      //     imageUrl: "http://106.14.25.175:9002/touxiang.jpg",
      //     industrySubjectName: "专家",
      //     legalPersonName: "陈远良",
      //     schoolTag: "新疆农业职业技术学院",
      //     villageName: "桥西村",
      //   },
      //   {
      //     contactWay: "123",
      //     imageUrl: "http://106.14.25.175:9002/touxiang.jpg",
      //     industrySubjectName: "专家",
      //     legalPersonName: "陈远良",
      //     schoolTag: "新疆农业职业技术学院",
      //     villageName: "桥西村",
      //   },
      //   {
      //     contactWay: "123",
      //     imageUrl: "http://106.14.25.175:9002/touxiang.jpg",
      //     industrySubjectName: "专家",
      //     legalPersonName: "陈远良",
      //     schoolTag: "新疆农业职业技术学院",
      //     villageName: "桥西村",
      //   },
      // ]
      this.title = title;
      this.type = type;
      this.visible = true;
    },
    gopage(num) {
      if (this.callback) {
        this.callback(num)
      }
    },
    //病虫害知识库
    showDisease(data, title, index = 1) {
      this.diseaseIndex = index;
      this.diseaseData = data;
      this.title = title;
      this.visible = true;
    },
    diseasemanualMonitory(data, callback) {
      this.callback = callback;
      this.diseasemanual = data;
      this.showDisease(data.list[0], data.list[0].diseaseName, 1);
    },
    diseaseinformationListpopup(data, title) {
      this.diseaseinformationList = data;
      this.title = data.crabPondName;
      this.visible = true;
    },
    close() {
      this.visible = false;
      this._promise && this._promise.resolve()
    }
  },
  watch: {
    '$route'() {
      this.close();
    }
  }
}
</script>

<style scoped lang="scss">
._alert {

  .mtn {
    margin-top: 0 !important;
  }

  ::-webkit-scrollbar {
    width: 0 !important
  }

  .t1 {
    width: 46%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .t2 {
    width: 18%;
  }

  .tableh {
    background-color: rgba(0, 50, 122, 1);
    height: 3rem;
    line-height: 3rem;
    text-align: center;
  }

  .page {
    width: 60%;
    margin: 2rem auto 0 auto;
    height: 5rem;
    line-height: 3rem;

    > div {
      display: inline-block;
      height: 3rem;
      width: 25%;
      cursor: pointer;
      background-color: rgba(47, 140, 255, 1);

      &:nth-child(2) {
        margin: auto 5%;
      }
    }
  }


  .tb {
    height: 2.5rem;
    line-height: 2.5rem;
    text-align: center;
  }

  .tb:nth-of-type(odd) {
    background: rgba(0, 0, 0, 0.15);
  }

  .wind-alert-dialog {
    top: 20%;
    width: 892px;
    left: 50%;
    height: 55%;
    opacity: 1;
    position: fixed;
    margin-left: -25%;
    font-size: 1.2rem;
    text-align: center;
    font-family: "Microsoft Yahei";
    border-radius: 8px;
    z-index: 999999999;
    background: url("~@/assets/images/icon/组 475@2x.png") no-repeat;
    background-size: 100% 100%;
    box-sizing: content-box;
    color: azure;
    &.h2{
      height: 70%;
    }
  }

  .wind-alert-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    display: block;
    position: fixed;
    z-index: 999999998;
    background-color: #000000;
  }

  .wind-alert-title {
    font-size: 2rem;
    height: 20.71%;
    display: flex;
    align-items: center;
    justify-content: center;

    /* border-bottom: 1px solid #ededed; */
  }

  .ccc {
    max-height: 73.29%;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 81.61%;
    margin: auto;
  }

  .dialog-list {
    width: 87.71%;
    height: 73.29%;
    max-height: 73.29%;
    margin: auto;
    .fx {
      max-height: calc(100% - 3rem);
      overflow-y: auto;
    }
  }

  .wind-alert-content {
    padding: 5px 15px 20px 15px;
  }

  .wind-alert-btn {
    color: #0582cd;
    font-size: 15px;
    line-height: 40px;
    font-weight: bold;
  }

  .animate-scale {
    animation-name: scale;
    animation-duration: 0.375s;
  }

  .close {
    background-color: azure;
    display: inline-block;
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    right: 3rem;
    top: 3rem;
    background: url("~@/assets/images/icon/关闭@2x.png") no-repeat;
    background-size: 100% 100%;
  }

  .wind-alert-content-list {
    width: 80%;
    height: 1%;
    overflow-y: auto;
    margin: 0 auto;
    margin-top: 2rem;
    text-align: left;

  }

  .cc {
    max-height: 73.29%;
    overflow-y: auto;

    .njzj {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 11px 50px;

      .njzj-item {
        width: 31.56%;
        height: 106px;
        display: flex;
        background: #06316F;
        align-items: center;
        padding: 0 16px;
        margin-top: 21px;
        &.noback{
          background: rgba(0,0,0,0)
        }
        .njzj-item-avatar {
          width: 65px;
          height: 65px;
        }

        .njzj-item-r {
          margin-left: 12px;
          line-height: 12px;

          .t {
            font-size: 22px;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #FFFFFF;

            span {
              &:last-child {
                font-size: 14px;
                font-family: Hiragino Sans GB;
                font-weight: normal;
                color: #FFFFFF;
                margin-left: 13px;
              }
            }
          }

          .b {
            font-size: 14px;
            height: 1rem;
            line-height: 1rem;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #FFFFFF;
            text-align: left;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
          }
        }
      }
    }
  }

  .wind-alert-content-disease {
    font-size: 1.2rem;
    width: 80%;
    height: 20rem;
    overflow-y: auto;
    margin: 0 auto;
    margin-top: 2rem;
    text-align: left;
  }


  .villagelist {
    width: 30.21%;
    height: 5rem;
    display: inline-block;
    background-color: #06316f;
    text-align: center;
    font-size: 1rem;
    line-height: 5rem;
    margin-top: 20px;
    &.kongbai{
      background-color: rgba(0,0,0,0);
    }
  }

  .tag {
    height: 15%;
  }

  .tag_item {
    width: 5rem;
    float: left;
    cursor: pointer;
    margin-right: 4rem;
  }

  .tag .show {
    padding-bottom: 0.5rem;
    border-bottom: 0.2rem solid rgb(0, 255, 255) !important;
  }

  .tag_content {
    height: 75%;
    padding-top: 2rem;
    /* background-color: antiquewhite; */
  }

  .diseas_img {
    width: 12rem;
    height: 12rem;
    float: left;
  }

  .diseas_message {
    float: left;
    width: 30rem;
    height: 12rem;
    line-height: 1.6;
    padding-left: 1rem;
  }

  .diseas_message > div {
    font-size: 1rem;
    height: 33%;
  }

  @keyframes scale {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  .history {
    width: 70%;
    height: 20rem;
    margin: 5rem auto;
    text-align: left;
  }

  .history > div {
    padding-top: 1rem;
    display: inline-block;
    font-size: 1.5rem;
    height: 7rem;
    margin: 1rem 1.5rem;
    background-color: rgba(56, 136, 255, 0.2);
    text-align: center;
    width: 25%;
  }

  .history > div:nth-child(1) > div:nth-child(2),
  .history > div:nth-child(4) > div:nth-child(2) {
    color: rgba(94, 255, 223, 1);
  }

  .history > div:nth-child(2) > div:nth-child(2),
  .history > div:nth-child(5) > div:nth-child(2) {
    color: rgba(255, 155, 11, 1);
  }

  .history > div:nth-child(3) > div:nth-child(2),
  .history > div:nth-child(6) > div:nth-child(2) {
    color: rgba(174, 253, 36, 1);
  }

  .history > div > div > span {
    font-size: 2.5rem;
  }
}
</style>
